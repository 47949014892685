if($('#wheels').length) {


  // additional finishes
  if(window.innerWidth > 640) {
    $(".product-item").mouseenter(function() {
      $(this).children().next().children('.other-finishes').css('opacity','1');
      $(this).children().next().children('.more-finishes').css('opacity','0');
      console.log($(this).children('.other-finishes').next().children());
    }).mouseleave(function() {
      $(this).children().next().children('.other-finishes').css('opacity','0');
      $(this).children().next().children('.more-finishes').css('opacity','1');
    });
  }
  // END : additional finishes

  let strDiameter = $("#formDiameter").val();		
  let strWidth  = $("#formWidth").val();
  let strFinish = $("#formFinish").val();
  let strBolt = $("#formBolt").val();
  let strPrice = $("#formPrice").val();
  
  

  $(".selectedFinish").click(function() {
    /*
    console.log('hello there');
    var val = $(this).attr('data-val'); 
    console.log(strFinish);
    strFinish = removeVal(strFinish,val);	
    console.log(strFinish);
		$('#formFinish').val(strFinish);
    $('#wheelsForm').submit();
    */
    $('#finish').val('');
    $('#slide-filter').submit();
   });

   $(".selectedFinish").click(function() {
    $('#finish').val('');
    $('#slide-filter').submit();
   });

   $(".selectedDiameter").click(function() {
    $('#wheel-diam').val('');
    $('#slide-filter').submit();
   });

   $(".selectedWidth").click(function() {
    $('#wheel-width').val('');
    $('#slide-filter').submit();
   });

   $(".selectedBolt").click(function() {
    $('#wheel-boltPat').val('');
    $('#slide-filter').submit();
   });

   $(".selectedPrice").click(function() {
    $('#price').val('');
    $('#slide-filter').submit();
   });

   $(".selectedSearch").click(function() {
    $('input[name="search"]').val('');
    $('#slide-filter').submit();
   });


   // move filter to fixed hear on mobile 
   //console.log($(window).width());
   if($(window).width() < 1200 ) {
     //$('.filter-sort').appendTo('.mobile-header-wheels-filter');
     
     let headerFilterHeight = $('.mobile-header-wheels-filter').height();
     //let headerFitmentHeight = $('.header-fitment-mobile').height();

     //let newHeaderHeight = headerFilterHeight + 90;
     $('#wheels').css('padding-top',headerFilterHeight);

     filterAppend();
     $(window).scroll(function(){
      filterAppend();
     });
     function filterAppend() {
      if($('header').offset().top > 220) {
        $('.filter-sort').appendTo('.mobile-header-wheels-filter');
      } else {
        $('.filter-sort').appendTo('.filter-sort-container');
      }
     }
   }
}


function trimCMA(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}

function removeVal(obj,value){
  let arrObj = obj.split(',')
  if(arrObj.length > 1){
  for( var i = 0; i < arrObj.length; i++){ 
        if ( arrObj[i] === value) {
            arrObj.splice(i, 1); 
          }
  }
  obj = arrObj.join(",")
  }else{
    obj ='';
  }
  return obj;
}