function moveElement(detachingEle, attachingEle) {
  let img = $('.' + detachingEle).detach();
  $('.' + attachingEle).append(img);
}

let elementToMove = 'js-move-finishes';
let desktopAnchor = 'wheel-detail-stage';
let mobileAnchor = 'wheel-detail-info';

if ($('.' + elementToMove).length) {
  if (Foundation.MediaQuery.atLeast('medium')) {
    moveElement(elementToMove, desktopAnchor);
  }
  $('.' + elementToMove).removeClass('hide');

  $(window).on('changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.atLeast('medium')) {
      moveElement(elementToMove, desktopAnchor);
    } else {
      moveElement(elementToMove, mobileAnchor);
    }
  });
}

///// Cart Functionality /////
if($('#wheel').length) {
    let pricingSectionDisplay = $('.pricing-section');
    let skuDisplay = $('.product .product-sku');
    let addToCart = $('.snipcart-add-item');
    let priceForSetDisplay = $('.price-for-set');
    let pricingDisplay = $('.pricing');
    let cartQuantity = 1;
    let titlePriceDisplay = $('.wheel-title .price');
    let titlePriceOriginal = titlePriceDisplay.attr('data-title-price');
    let msrpDisplay = $('.msrp-display');
    
    skuDisplay.hide();
    pricingSectionDisplay.hide();

    $('#product-select').change(function(){
      //pricingDisplay.fadeIn();
      skuDisplay.fadeIn();
      pricingSectionDisplay.fadeIn();
      msrpDisplay.fadeIn();

      // price
      let price = $(this).find(':selected').attr('data-price');
      //let priceForSet = price * 4;
      let priceForSet = price;
      let priceString = 'Call For Price';
      let titlePrice = '$' + price + ' <span>per wheel</span>';
      if(typeof price == 'undefined') {
        price = 0;
        priceForSet = price * 4;
        pricingSectionDisplay.hide();
        skuDisplay.hide();
        msrpDisplay.hide();
        titlePrice = titlePriceOriginal;
      }
      //console.log(price);
      //console.log(priceForSet);
      if(priceForSet !== 0) {
        priceString = '$' + priceForSet;
      } 
      if(price == 0) {
        pricingDisplay.html(priceString);
        titlePriceDisplay.html(titlePrice);
      } else {
        pricingDisplay.html('total: <span class="price-for-set">'+priceString+'</span>');
        //priceForSetDisplay.html(priceString);
        titlePriceDisplay.html(titlePrice);
      }
      
      // MSRP
      let msrp = $(this).find(':selected').attr('data-msrp');
      let msrpPrice = '$' + msrp;
      $('.msrp-pricing').html(msrpPrice)

      // quantity
      $('input[name="quantity"]').val(cartQuantity);


      // sku
      let sku = $(this).find(':selected').val();
      $('.product-sku-display').html(sku);

      // description
      let description = $(this).find(':selected').attr('data-description');

      // weight
      let weight = $(this).find(':selected').attr('data-weight');

      // stock
      let stock = $(this).find(':selected').attr('data-stock');
      let stockDisplay;
      if(typeof stock != 'undefined') {
        stockDisplay = '(' + stock + ' in stock)';
      } else {
        stockDisplay = '';
      }
      $('.product-stock-display').html(stockDisplay);
      $('input[type="number"]').attr('max',stock);

      // url
      let url = $(this).find(':selected').attr('data-url');

      // cart
      addToCart.attr('data-item-id',sku)
        .attr('data-item-price',price)
        .attr('data-item-url',url)
        .attr('data-item-quantity',cartQuantity)
        .attr('data-item-max-quantity',stock)
        .attr('data-item-description',description)
        .attr('data-item-weight',weight);
    });

    $('input[name="quantity"]').change(function(){
      let currentQty = $(this).val();
      let currentPrice = $('#product-select').find(':selected').attr('data-price');
      let price = 0;
      let priceString = '';
      let contains = '.';
      if (typeof currentPrice != 'undefined') {
        price = currentPrice * currentQty;
        if(!Number.isInteger(price)) {
          price = price.toFixed(2);
        }
        priceString = '$' + price;
      }
      //console.log(price);
      //console.log(priceString);
      //console.log(currentPrice);
      addToCart.attr('data-item-quantity',currentQty);
      //priceForSetDisplay.html(priceString);
      pricingDisplay.html('total: <span class="price-for-set">'+priceString+'</span>');

    });

/*
    $('.finishes .alt-finish').click(function(){
      let wheel = $(this).attr('data-wheel');
      let finishID = $(this).attr('data-finish');
      $.get('/ajax/getFinishImages.cfm?id=' + wheel + '&finishID=' + finishID, function loadImages(data) {
        $('.wheel-detail-stage').html(data);
        //$('.finish h3').html(finish);
        initializeMainImage();
      });
    });
*/











  // finish display
  $('input[name="finish"]').change(function(){
    let $finish = $(this).data('finishitem');
    let $totalItems = $('.finish-item-options').data('items');
    let $category = $('.cart-options').data('couponevent');

    if($finish == 'custom') {
      $('.finish-item-options.custom').fadeIn();
      $('.finish-item-options.standard').fadeOut();
      updateCategory($totalItems,$category);
    } else {
      $('.finish-item-options.custom').fadeOut();
      $('.finish-item-options.standard').fadeIn();
  
      // reset pricing to original if set to standard;
      
      let $finish = $(`input[name="1:Finish"]`).data('original');
      let $finishValue = 0;
      let $finishKey = $(`input[name="1:Finish"]`).data('finishkey');
      let $color = '';
      let $colorValue = 0;
      let standardFinishCount = $('.standard input[name="finish-finish"').length;
      $category = '';

      updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,'');
      updateCategory($totalItems,$category);

      $('.custom input[name="finish-finish"').each(function(){
        $(this).prop('checked', false);
      });
      $('input[name="finish-color"').each(function(){
        $(this).prop('checked', false);
      });
      
      if( standardFinishCount < 2 ) {
        $('.standard input[name="finish-finish"').prop('checked', true);
      }
      $('input[name="finish-color-other"]').val('');
    }
    isReadyToSubmit();
  });

  // add to hidden - finish
  $('.finish-item-options input[name="finish-finish"]').change(function(){
    let $totalItems = $('.finish-item-options').data('items');
    let $finish = $(this).data('finish');
    let $finishValue = $(this).data('price');
    let $finishKey = $(this).data('finishkey');
    let $colorOption = $('input[name="finish-color"]:checked').data('coloritem');
    let $color = $('input[name="finish-color-other"]').val();
    let $colorValue = $('input[name="finish-color"]:checked').data('price');

    // defined the undefined color
    if (typeof $colorOption == 'undefined') {
      $colorOption = $('input[name="finish-color"]:checked').data('coloritem');
      if($colorOption == 'other' ){
        $color = $('input[name="finish-color-other"]').val();
      } else {
        $color = '';
      }
    } else if ($colorOption == 'other' ){
      $color = $('input[name="finish-color-other"]').val();
    }

    if (typeof $colorValue == 'undefined') {
      $colorValue = $('input[name="finish-color"]:checked').data('price');
      if (typeof $colorValue == 'undefined') {
        $colorValue = 0;
      }
    }

    isReadyToSubmit();
    updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,$finishKey);
  });

  // add to hidden - finish and color
  $('.finish-item-options input[name="finish-color"]').change(function(){
    let $totalItems = $('.finish-item-options').data('items');
    let $finish = $('input[name="finish-finish"]:checked').data('finish');
    let $finishValue = $('input[name="finish-finish"]:checked').data('price');
    let $colorOption = $(this).data('coloritem');
    let $color = '';
    let $colorValue = $(this).data('price');

    // defined the undefined finish
    if (typeof $finish == 'undefined') {
      $finish = $('input[name="finish-finish"]:checked').data('finish');
      if (typeof $finish == 'undefined') {
        $finish = 'Brushed Titanium';
      }
    }
    if (typeof $finishValue == 'undefined') {
      $finishValue = $('input[name="finish-finish"]:checked').data('price');
      if (typeof $finishValue == 'undefined') {
        $finishValue = 0;
      }
    }

    // defined the undefined color
    if ($colorOption == 'other' ){
      $color = $('input[name="finish-color-other"]').val();
    } 
    
    isReadyToSubmit();
    updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,'');
  });

  // cart submit - set color
  $('.cart-submit').click(function(){
    let $totalItems = $('.finish-item-options').data('items');
    let $finish = $('input[name="finish-finish"]:checked').data('finish');
    let $finishValue = $('input[name="finish-finish"]:checked').data('price');
    let $finishKey = $('input[name="finish-finish"]:checked').data('finishkey');
    let $color = $('input[name="finish-color-other"]').val();
    let $colorValue = $('input[name="finish-color"]:checked').data('price');
    if (typeof $finish == 'undefined') {
      $finish = 'Brushed Titanium';
    }
    if (typeof $finishValue == 'undefined') {
      $finishValue = 0;
    }
    if (typeof $color == 'undefined') {
      $color = '';
    }
    if (typeof $colorValue == 'undefined') {
      $colorValue = 0;
    }
    updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,$finishKey);

    $('form').submit();
  });

  $('.finish-quantity, input[name="finish-color-other"]').on('change',function(){
    isReadyToSubmit();
  });

  $('.finish-quantity, input[name="finish-color-other"]').on('click',function(){
    isReadyToSubmit();
  });

  $('input[name="finish-color-other"]').on('keyup',function(){
    isReadyToSubmit();
  });

  function isReadyToSubmit() {
    let $totalItems = $('.finish-item-options').data('items');
    let $qtyCheck = false;
    let $finishCheck = false;
    let $finishValue = $(`input[name="finish"]:checked`).val();

    // check finish
    if (typeof $finishValue != 'undefined') {
      let $finish = $(`input[name="finish"]:checked`).data('finishitem');

      if($finish == 'custom') {
        let $finishFinish = $(`.custom input[name="finish-finish"]`);
        let $finishColor = $(`.custom input[name="finish-color"]`);
        let $finishColorOther = $(`.custom input[name="finish-color-other"]`);
        let $customFinishCheck = false;
        let $customColorCheck = false;
        // loop through finish selections
        $finishFinish.each(function(){
          if ($(this).prop('checked')) {
            $customFinishCheck = true;
          } 
        });
        // loop through color selections
        $finishColor.each(function(){
          if ($(this).prop('checked')) {
            if($(this).data('coloritem') == 'other') {
              if($finishColorOther.val() != '' && typeof $finishColorOther.val() != 'undefined') {
                $customColorCheck = true;
              } 
            } else {
              $customColorCheck = true;
            }
          } 
        });
        if($customColorCheck == true && $customFinishCheck == true) {
          $finishCheck = true;  
        }
      } else {
        $finishCheck = true;
      }
    }

    // check quantity
    for (let i=0; i<$totalItems; i++){
      let $itemCount = i+1
      let $qtyValue = $(`input[name="${$itemCount}:quantity"]`).val();
      
      if($qtyValue > 0) {
        $qtyCheck = true;
      }
    }

    if ($qtyCheck == true && $finishCheck == true) {
      $('button.cart-submit').prop('disabled',false);    
    } else {
      $('button.cart-submit').prop('disabled',true);
    }
     
  }

  function updatePrice($totalItems,$color,$colorValue,$finish,$finishValue,$finishKey) {
    for (let i=0; i<$totalItems; i++){
      let $itemCount = i+1
      let $colorHTML = $(`input[name="${$itemCount}:Color"]`);
      let $finishHTML = $(`input[name="${$itemCount}:Finish"]`);
      let $priceHTML = $(`input[name="${$itemCount}:price"]`);
      let $skuHTML = $(`input[name="${$itemCount}:SKU"]`);
      let $currentPrice = $priceHTML.data('original');
      let $price = parseInt($currentPrice) + parseInt($finishValue) + parseInt($colorValue);
      let $sku = $skuHTML.data('original');
      if($finishKey !== '') {
        $sku = $skuHTML.data('short')+$finishKey;
      }
      
      $finishHTML.val($finish);
      $colorHTML.val($color);
      $priceHTML.val($price);
      $skuHTML.val($sku);
    }
  }

  function updateCategory($totalItems,$category) {
    for (let i=0; i<$totalItems; i++){
      let $itemCount = i+1
      let $categoryHTML = $(`input[name="${$itemCount}:category"]`);
      
      $categoryHTML.val($category);
    }
  }
}


$('#wheel .cart-size select').change(function(){
  let bolt = $(this).children(':selected').attr('selected',true).data('bolt');
  let sku = $(this).children(':selected').attr('selected',true).data('sku');
  let price = $(this).children(':selected').attr('selected',true).data('price');
  let finish = $(this).children(':selected').attr('selected',true).data('finish');
  
  $('input[name="SKU"]').val(sku);
  $('input[name="bolt"]').val(bolt);
  $('input[name="price"]').val(price);
  $('input[name="Finish"]').val(finish);
  
  // reset options
  $("#wheel .cart-options.colors select").val($("#wheel .cart-options.colors select option:first").val());
  $("#wheel .cart-options.finishes select").val($("#wheel .cart-options.finishes select option:first").val());
  $("#wheel .cart-options.finishes select").prop('disabled',true);
});

$('#wheel .cart-options.colors select').change(function(){
  let currentPrice = $('#wheel .cart-size select').children(':selected').attr('selected',true).data('price');
  let colorPrice = $(this).children(':selected').attr('selected',true).data('price');
  let color = $(this).children(':selected').attr('selected',true).data('color');
  
  currentPrice = parseInt(currentPrice);
  colorPrice = parseInt(colorPrice);
  
  // Calculate new price
  let newPrice = currentPrice + colorPrice;
  
  // set new price and color
  $('input[name="price"]').val(newPrice);
  $('input[name="Color"]').val(color);
  
  // refresh finishes
  $("#wheel .cart-options.finishes select").val($("#wheel .cart-options.finishes select option:first").val());
  $("#wheel .cart-options.finishes select").prop('disabled',false);
});

$('#wheel .cart-options.finishes select').change(function(){
  let currentPrice = $('#wheel .cart-size select').children(':selected').attr('selected',true).data('price');
  let colorPrice = $('#wheel .cart-options.colors select').children(':selected').attr('selected',true).data('price');
  let finishPrice = $(this).children(':selected').attr('selected',true).data('price');
  let finish = $(this).children(':selected').attr('selected',true).data('finish');
  
  currentPrice = parseInt(currentPrice);
  colorPrice = parseInt(colorPrice);
  finishPrice = parseInt(finishPrice);
  
  // Calculate new price
  let newPrice = currentPrice + colorPrice + finishPrice;
  
  // set new price and finish
  $('input[name="price"]').val(newPrice);
  $('input[name="Finish"]').val(finish);
});


///// add more gallery /////
  $('.wheel-detail-gallery .navigation a').click(function(){
    var $this = $(this);
    var currentPage = $this.attr('data-page');
    var nextPage = parseInt(currentPage) + 1;
    var currentDisplay = $this.parent().prev();
    var getLast = $this.parent().prev().find('.column:last-child');
    var currentListCount = currentDisplay.find('.column').length;
    var nextListCount = currentListCount + 12;
    var maxListCount = $this.attr('data-max');
    var wheelID = $this.attr('data-wheel');
    
    // remove load more link
    if(nextListCount >= maxListCount) {
      $this.fadeOut();
    }
    
    // update page
    loadMoreGallery($this,nextPage)
    
    // display feed
    $.get('/ajax/getGallery.cfm?id=' + $this.data('id') + '&wheel=' + wheelID +'&page=' + currentPage, function getNewImages(data) {
      $(currentDisplay).append(data);
    });
  });
  
  function loadMoreGallery(element,page) {
    element.attr('data-page',page);

  }


//// mobile display
function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

if( $('#wheel .product-next-prev').length ) {
  if (getWidth() < 640 ) {
    $('.product-next-prev').insertBefore(  $('.wheel-detail-stage') );
  }
}