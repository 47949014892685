// landing page header
$('.page-header-slider').slick({
    autoplay: true,
    dots: true,
    arrows: true,
    adaptiveHeight: true,
    autoplaySpeed: 4000,
    nextArrow: '<button class="featured-next featured-arrow slick-next" aria-label="Next" type="button"><img src="/images/utility/next-arrow.png" /></button>',
    prevArrow: '<button class="featured-prev featured-arrow slick-prev" aria-label="Prev" type="button"><img src="/images/utility/prev-arrow.png" /></button>',
});


// details page
$('.js-gallery-main-img').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

$('.js-gallery-main-img').slick({
  adaptiveHeight: false,
  arrows: true,
  nextArrow: '<button class="featured-next featured-arrow slick-next" aria-label="Next" type="button"><img src="/images/utility/next-arrow.png" /></button>',
    prevArrow: '<button class="featured-prev featured-arrow slick-prev" aria-label="Prev" type="button"><img src="/images/utility/prev-arrow.png" /></button>',
  asNavFor: '#gallery-thumbs',
  fade: true,
  lazyLoad: 'ondemand'
});
$('#gallery-thumbs').slick({
  arrows: false,
  asNavFor: '.js-gallery-main-img',
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [{
    breakpoint: 600,
    settings: {
      dots: true,
      slidesToScroll: 3,
      slidesToShow: 3
    }
  }]
});

$(function () {
  // Header Filters
  $('#gallery-make').change(function changeMake() {
    $('#gallery-model').val('');
    $('#gallery-brand').val('');
    $('#gallery-wheel').val('');
    $('#gallery-search form').submit();
  });

  $('#gallery-model').change(function changeModel() {
    $('#gallery-brand').val('');
    $('#gallery-wheel').val('');
    $('#gallery-search form').submit();
  });
  
  $('#gallery-wheel').change(function changeBrand() {
    $('#gallery-search form').submit();
  });

})