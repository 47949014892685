$('.js-home-slider').slick({
    autoplay: true,
    dots: false,
    arrows: false,
    adaptiveHeight: true,
    autoplaySpeed: 4000,
});

if ($('.hp').length) {
    if ($(window).width() < 640) {
        $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
        $('.js-footer-social').insertAfter('.support-list');
    }

    var moveHTML = debounce(function () {
        if ($(window).width() < 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
            $('.js-footer-social').insertAfter('.support-list');
        }
        if ($(window).width() > 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__header');
            $('.js-footer-social').insertAfter('.footer__about');
        }
    }, 500);

    window.addEventListener('resize', moveHTML);
}


// add animations
$(window).scroll(function() {  
  var animationList = [
    'fadeIn',
    'fadeInUp',
    'zoomIn'
  ];
  var wH = $(window).height(),
      wS = $(this).scrollTop();
  $('.hp .animated, .footer .animated').each(function() {
    var randomNum = Math.floor(Math.random() * (animationList.length - 1));
    var hT = $(this).offset().top,
        hH = $(this).outerHeight();
    if (hT - $(window).scrollTop() - wh <= 0) {
      $(this).addClass(animationList[randomNum]).one('animationend oAnimationEnd mozAnimationEnd webkitAnimationEnd', function(){
        $(this).removeClass('animated');
      });
    } 
  });   
});

// brands slider
$('.brands-slider').slick({
  centerMode: true,
  //centerPadding: '60px',
  slidesToShow: 1,
  nextArrow: '<button class="brands-next brands-arrow" aria-label="Next" type="button"><i class="fas fa-chevron-right"></i></button>',
  prevArrow: '<button class="brands-prev brands-arrow" aria-label="Prev" type="button"><i class="fas fa-chevron-left"></i></button>',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ]
});

if($('.hp').length) {
   // additional finishes
   if(window.innerWidth > 640) {
    $(".product-item").mouseenter(function() {
      $(this).children().next().children('.other-finishes').css('opacity','1');
      $(this).children().next().children('.more-information').css('opacity','0');
    }).mouseleave(function() {
      $(this).children().next().children('.other-finishes').css('opacity','0');
      $(this).children().next().children('.more-information').css('opacity','1');
    });
  }
  // END : additional finishes
}


// scroll magic
/*var controller = new ScrollMagic.Controller({
  globalSceneOptions: {
    triggerHook: 'onLeave'
  }
});

var slides = document.querySelectorAll('section.section');

for (var i=0; i<slides.length; i++) {
  new ScrollMagic.Scene({
    triggerElement: slides[i]
  })
  .setPin(slides[i])
  //.addIndicators()
  .addTo(controller);
}*/